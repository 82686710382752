import _ from 'lodash';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import { reactive, computed } from '@vue/composition-api';
import World from '@svg-maps/world';
import HttpClient from '../HttpClient';
import numeral from 'numeral';
import { DateTime } from 'luxon';

export default function Reports(currency) {
  const state = reactive({
    currency,
    symbol: currency == 'USD' ? '$' : '€',
    CcrDaysItemsLoading: false,
    CcrDaysItems: [],
    CCRTopByOfferItemsLoading: false,
    CCRTopByOfferItems: [],
    CCRTopByLocationsItemsLoading: false,
    CCRTopByLocationsItems: [],
    CCRLast7DaysByDeviceItemsLoading: false,
    CCRLast7DaysByDeviceItems: [],
    CCRLast7DaysByOsItemsLoading: false,
    CCRLast7DaysByOsItems: [],
    SomeLoading: computed(
      () =>
        state.CcrDaysItemsLoading ||
        state.CCRLast7DaysByDeviceItemsLoading ||
        state.CCRLast7DaysByOsItemsLoading
    ),
    ClicksChart: computed(() => ({
      series: [
        {
          name: 'Clicks',
          data: state.CcrDaysItems.map((item) =>
            item['transactions'] ? _.round(item['transactions'], 2) : 0
          ),
          color: '#FF0000',
        },
      ],
      options: {
        chart: {
          height: 280,
          type: 'line',
          zoom: {
            enabled: false,
          },
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 16,
            opacity: 0.3,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: [3],
          dashArray: [0],
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 10,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return numeral(val).format('0,0');
          },
        },
        xaxis: {
          categories: state.CcrDaysItems.map((currentValue) => currentValue['createdAt']),
          labels: {
            formatter: function (val) {
              return DateTime.fromISO(val)
                .setZone(localStorage.getItem('timeZone') || DateTime.local().zoneName)
                .toLocaleString(DateTime.DATE_SHORT);
            },
          },
        },
        yaxis: [
          {
            seriesName: 'Clicks',
            axisTicks: {
              show: false,
              color: '#FF0000',
            },
            axisBorder: {
              show: true,
              color: '#FF0000',
            },
            labels: {
              show: window.innerWidth >= 1264,
              style: {
                colors: '#FF0000',
              },
              formatter: function (val) {
                return numeral(val).format('0,0');
              },
            },
            title: {
              text: 'Clicks',
              style: {
                color: '#FF0000',
              },
            },
          },
        ],
        tooltip: {
          color: '#42A5F5',
        },
        grid: {
          borderColor: 'transparent',
          padding: {
            left: 5,
            right: 2,
          },
        },
      },
    })),
    ConversionsChart: computed(() => ({
      series: [
        {
          name: 'Conversions',
          data: state.CcrDaysItems.map((item) => (item['conversions'] ? item['conversions'] : 0)),
          color: '#FFCC00',
        },
      ],
      options: {
        chart: {
          height: 280,
          type: 'line',
          zoom: {
            enabled: false,
          },
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: [3],
          dashArray: [0],
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 10,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return numeral(val).format('0,0');
          },
        },
        xaxis: {
          categories: state.CcrDaysItems.map((currentValue) => currentValue['createdAt']),
          labels: {
            formatter: function (val) {
              return DateTime.fromISO(val)
                .setZone(localStorage.getItem('timeZone') || DateTime.local().zoneName)
                .toLocaleString(DateTime.DATE_SHORT);
            },
          },
        },
        yaxis: [
          {
            seriesName: 'Conversions',

            axisTicks: {
              show: true,
              color: '#FFCC00',
            },
            axisBorder: {
              show: true,
              color: '#FFCC00',
            },
            labels: {
              show: window.innerWidth >= 1264,
              style: {
                colors: '#FFCC00',
              },
              formatter: function (val) {
                return numeral(val).format('0,0');
              },
            },
            title: {
              text: 'Conversions',
              style: {
                color: '#FFCC00',
              },
            },
          },
        ],
        tooltip: {
          color: '#42A5F5',
        },
        grid: {
          borderColor: 'transparent',
          padding: {
            left: 5,
            right: 2,
          },
        },
      },
    })),
    PayoutChart: computed(() => ({
      series: [
        {
          name: 'Payout',
          data: state.CcrDaysItems.map((item) =>
            item[`revenue${currency}`] ? _.round(item[`revenue${currency}`], 2) : 0
          ),
          color: '#0088EE',
        },
      ],
      options: {
        chart: {
          height: 280,
          type: 'line',
          zoom: {
            enabled: false,
          },
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: [3],
          dashArray: [0],
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 10,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return numeral(val).format('0,0.00');
          },
        },
        xaxis: {
          categories: state.CcrDaysItems.map((currentValue) => currentValue['createdAt']),
          labels: {
            formatter: function (val) {
              return DateTime.fromISO(val)
                .setZone(localStorage.getItem('timeZone') || DateTime.local().zoneName)
                .toLocaleString(DateTime.DATE_SHORT);
            },
          },
        },
        yaxis: [
          {
            seriesName: 'Payout',
            axisTicks: {
              show: true,
              color: '#0088EE',
            },
            axisBorder: {
              show: true,
              color: '#0088EE',
            },
            labels: {
              show: window.innerWidth >= 1264,
              style: {
                colors: '#0088EE',
              },
              formatter: function (val) {
                return numeral(val).format('0,0.00');
              },
            },
            title: {
              text: 'Payout',
              style: {
                color: '#0088EE',
              },
            },
          },
        ],
        tooltip: {
          color: '#42A5F5',
          y: {
            formatter: function (val) {
              return state.symbol + ' ' + val;
            },
          },
        },
        grid: {
          borderColor: 'transparent',
          padding: {
            left: 5,
            right: 2,
            top: 0,
            bottom: 0,
          },
        },
      },
    })),
    CountryChart: computed(() => ({
      selectedLocations: Object.values(
        state.CCRTopByLocationsItems.reduce((accumulator, currentValue) => {
          let country = _.find(
            World.locations,
            (c) => _.upperCase(c.id) == _.upperCase(currentValue.meta.country)
          );
          if (country) {
            accumulator[_.upperCase(country.id)] = country.id;
          }
          return accumulator;
        }, {})
      ),
    })),
    DeviceChart: computed(() =>
      (state.CCRLast7DaysByDeviceItems ? state.CCRLast7DaysByDeviceItems : []).reduce(
        (accumulator, currentValue) => {
          accumulator.options.xaxis.categories.push(`${currentValue['meta']['deviceBrand']}`);
          accumulator.series[0].data.push(
            currentValue['transactions'] && _.isNumber(currentValue['transactions'])
              ? currentValue['transactions']
              : 0
          );
          accumulator.series[1].data.push(
            currentValue['conversions'] && _.isNumber(currentValue['conversions'])
              ? currentValue['conversions']
              : 0
          );
          accumulator.series[2].data.push(
            currentValue[`revenue${currency}`] && _.isNumber(currentValue[`revenue${currency}`])
              ? _.round(currentValue[`revenue${currency}`], 2)
              : 0
          );

          return accumulator;
        },
        {
          series: [
            {
              name: 'Clicks',
              color: '#FF0000',
              data: [],
            },
            {
              name: 'Conversions',
              color: '#FFCC00',
              data: [],
            },
            {
              name: 'Payout',
              color: '#0088EE',
              data: [],
            },
          ],
          options: {
            chart: {
              type: 'bar',
              stacked: true,
              height: 350,
              zoom: {
                enabled: false,
              },
              dropShadow: {
                enabled: false,
                color: '#000',
                top: 16,
                left: 0,
                blur: 16,
                opacity: 0.1,
              },
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: [],
              labels: {
                show: true,
                formatter: function (val) {
                  if (Number.isInteger(val)) {
                    return numeral(val).format('0,0');
                  } else {
                    return numeral(val).format('0,0.00');
                  }
                },
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: false,
                },
              },
            },
            yaxis: {
              reversed: false,
              axisTicks: {
                show: false,
              },
            },
            tooltip: {
              y: {
                formatter: function (val /*, opts*/) {
                  if (Number.isInteger(val)) {
                    return numeral(val).format('0,0');
                  } else {
                    return state.symbol + ' ' + numeral(val).format('0,0.00');
                  }
                },
              },
            },
          },
        }
      )
    ),
    OsChart: computed(() =>
      (state.CCRLast7DaysByOsItems ? state.CCRLast7DaysByOsItems : []).reduce(
        (accumulator, currentValue) => {
          accumulator.options.xaxis.categories.push(`${currentValue['meta']['deviceOs']}`);
          accumulator.series[0].data.push(
            currentValue['transactions'] && _.isNumber(currentValue['transactions'])
              ? currentValue['transactions']
              : 0
          );
          accumulator.series[1].data.push(
            currentValue['conversions'] && _.isNumber(currentValue['conversions'])
              ? currentValue['conversions']
              : 0
          );
          accumulator.series[2].data.push(
            currentValue[`revenue${currency}`] && _.isNumber(currentValue[`revenue${currency}`])
              ? _.round(currentValue[`revenue${currency}`], 2)
              : 0
          );
          return accumulator;
        },
        {
          series: [
            {
              name: 'Clicks',
              color: '#FF0000',
              data: [],
            },
            {
              name: 'Conversions',
              color: '#FFCC00',
              data: [],
            },
            {
              name: 'Payout',
              color: '#0088EE',
              data: [],
            },
          ],
          options: {
            chart: {
              type: 'bar',
              stacked: true,
              height: 350,
              zoom: {
                enabled: false,
              },
              dropShadow: {
                enabled: false,
                color: '#000',
                top: 16,
                left: 0,
                blur: 16,
                opacity: 0.1,
              },
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: [],
              labels: {
                show: true,
                formatter: function (val) {
                  if (Number.isInteger(val)) {
                    return numeral(val).format('0,0');
                  } else {
                    return numeral(val).format('0,0.00');
                  }
                },
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: false,
                },
              },
            },
            yaxis: {
              reversed: true,
              axisTicks: {
                show: false,
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  if (Number.isInteger(val)) {
                    return numeral(val).format('0,0');
                  } else {
                    return state.symbol + ' ' + numeral(val).format('0,0.00');
                  }
                },
              },
            },
          },
        }
      )
    ),
  });

  const actions = {
    async loadItems() {
      set(state, 'CcrDaysItemsLoading', true);
      set(state, 'CCRTopByOfferItemsLoading', true);
      set(state, 'CCRTopByLocationsItemsLoading', true);
      set(state, 'CCRLast7DaysByDeviceItemsLoading', true);
      set(state, 'CCRLast7DaysByOsItemsLoading', true);
      try {
        const result = await HttpClient.runProcess('TSStaticService', 'runTimelineStats', {
          filters: { groupBy: ['day'] },
          options: {},
        });
        set(state, 'CcrDaysItems', sortBy(result.resultSet, 'createdAt'));
      } catch (error) {
        set(state, 'CcrDaysItems', []);
      } finally {
        set(state, 'CcrDaysItemsLoading', false);
      }
      try {
        const result = await HttpClient.runProcess('TSStaticService', 'runTimelineStats', {
          filters: { groupBy: ['day'] },
          options: {},
        });
        set(state, 'CCRTopByOfferItems', sortBy(result.resultSet, 'createdAt'));
      } catch (error) {
        set(state, 'CCRTopByOfferItems', []);
      } finally {
        set(state, 'CCRTopByOfferItemsLoading', false);
      }
      try {
        const result = await HttpClient.runProcess('TSStaticService', 'runTopStats', {
          filters: { groupBy: 'country' },
        });
        set(state, 'CCRTopByLocationsItems', sortBy(result.resultSet, 'revenue'));
      } catch (error) {
        set(state, 'CCRTopByLocationsItems', []);
      } finally {
        set(state, 'CCRTopByLocationsItemsLoading', false);
      }
      try {
        const result = await HttpClient.runProcess('TSStaticService', 'runTopStats', {
          filters: { groupBy: 'deviceBrand' },
        });
        set(state, 'CCRLast7DaysByDeviceItems', sortBy(result.resultSet, 'revenue'));
      } catch (error) {
        set(state, 'CCRLast7DaysByDeviceItems', []);
      } finally {
        set(state, 'CCRLast7DaysByDeviceItemsLoading', false);
      }
      try {
        const result = await HttpClient.runProcess('TSStaticService', 'runTopStats', {
          filters: { groupBy: 'deviceOs' },
        });
        set(state, 'CCRLast7DaysByOsItems', sortBy(result.resultSet, 'revenue'));
      } catch (error) {
        set(state, 'CCRLast7DaysByOsItems', []);
      } finally {
        set(state, 'CCRLast7DaysByOsItemsLoading', false);
      }
    },
  };
  return { state, actions };
}
