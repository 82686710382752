import axios from 'axios';
const USER_KEY = 'USER_KEY';
const TOKEN_KEY = 'TOKEN_KEY';
const SERVER_URL_V2 = process.env.VUE_APP_SERVER_URL_V2;
const REST_ACTIONS = { GET: 'GET', DELETE: 'DELETE', POST: 'POST', PUT: 'PUT' };

const API_V2 = axios.create({
  baseURL: SERVER_URL_V2,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': localStorage.getItem(TOKEN_KEY),
  },
});
export default {
  /**
   * Api V2 call function.
   * @param {String} action
   * @param {String} route
   * @param {Object} data
   */
  async callFunctionV2(action, route, data) {
    const URL = `/api/v2/${route}`;
    try {
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem(TOKEN_KEY),
        },
      };
      switch (action) {
        case REST_ACTIONS.GET: {
          config.params = data;
          const result = await API_V2.get(URL, config);
          return result.data;
        }
        case REST_ACTIONS.DELETE: {
          config.data = data;
          const result = await API_V2.delete(URL, config);
          return result.data;
        }
        case REST_ACTIONS.POST: {
          const result = await API_V2.post(URL, data, {
            headers: {
              'x-access-token': localStorage.getItem(TOKEN_KEY),
              'Content-Type': data instanceof FormData ? 'multipart/form-data' : undefined,
            },
          });
          return result;
        }
        case REST_ACTIONS.PUT: {
          const result = await API_V2.put(URL, data, {
            headers: {
              'x-access-token': localStorage.getItem(TOKEN_KEY),
            },
          });
          return result;
        }
        default:
          return null;
      }
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        await this.singOut();
        window.location.href = '/';
      }
      return error;
    }
  },
  async singOut() {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
    return true;
  },
};
