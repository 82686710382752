/**
 * ItemState represents the possible state of an instantiated object.
 */
export default {
  /**
   * No object has been selected, the variable is null.
   */
  NOT_SELECTED: 'NOT_SELECTED',
  /**
   * An object that does not represent a row in the database is considered as TRANSIENT. When the object is in TRANSIENT state then it will not contain any identifier (primary key value).
   */
  TRANSIENT: 'TRANSIENT',
  /**
   * An object representing a row in the database and has an identifier value is considered PERSISTENT.
   */
  PERSISTENT: 'PERSISTENT',
  /**
   * An object that represents a row in the database and has an identifier value, but some attributes differ from the persisted object (it was modified) is considered DETACHED.
   */
  DETACHED: 'DETACHED',
};
