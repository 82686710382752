<template>
  <v-btn
    @click="onClick"
    :loading="loading"
    :disabled="disabled"
    :color="color"
    small
    :depressed="depressed"
    :icon="!depressed"
    class="mx-1"
  >
    <v-icon v-text="icon" :class="textColor" />
  </v-btn>
</template>
<script>
export default {
  name: 'pcc-button-tooltip',
  data() {
    return {
      show: false,
    };
  },
  props: {
    icon: String,
    color: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: 'text--secondary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    tooltip: String,
    position: String, // top | right | bottom | left
    badge: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
