import _ from 'lodash';
import { reactive } from '@vue/composition-api';
import HttpClientV2 from '../HttpClientV2';
import moment from 'moment';

export default function Billing(currency) {
  const state = reactive({
    _affiliates: null,
    loading: false,
    items: [],
    total: 0,
    filters: {
      dateRangeSelected: 'All time',
      dateRange: {
        createdFrom: moment('2020-01-01').utc().startOf('day').toISOString(),
        createdUpTo: moment().utc().endOf('day').toISOString(),
      },
    },
    options: {
      page: 0,
      rowsPerPage: 20,
    },
    item: null,
    isInitialLoad: true,
  });

  const actions = {
    async loadItems() {
      try {
        _.set(state, 'loading', true);
        if (state.isInitialLoad) {
          _.set(state, 'isInitialLoad', false);
        }
        const defaultDocumentStatus = 'PENDING,PAID,UNPAID';
        const params = {
          _affiliates: state._affiliates,
          startDate: state.filters.dateRange.createdFrom,
          endDate: state.filters.dateRange.createdUpTo,
          limit: state.options.rowsPerPage,
          page: state.options.page,
          sortBy: state.options.sortBy,
          descending: state.options.descending,
        };
        if (!_.isEmpty(state.filters.paymentTerms)) {
          params['paymentTerms'] = state.filters.paymentTerms.toString();
        }
        if (!_.isEmpty(state.filters.documentType)) {
          params['documentType'] = state.filters.documentType.toString();
        }
        if (!_.isEmpty(state.filters.conversion)) {
          params['conversion'] = state.filters.conversion.toString();
        }
        if (!_.isEmpty(state.filters.invoiceStatus)) {
          if (!_.isEmpty(params['documentStatus'])) {
            params['documentStatus'] += ',' + state.filters.invoiceStatus.toString();
          } else {
            params['documentStatus'] = state.filters.invoiceStatus.toString();
          }
        } else if (_.isEmpty(state.filters.invoiceStatus)) {
          params['documentStatus'] = defaultDocumentStatus;
        }
        const result = await HttpClientV2.callFunctionV2('GET', 'Billing', params);
        _.set(state, 'items', result.billing);
        _.set(state, 'total', result.total);
      } catch (error) {
        _.set(state, 'items', []);
        _.set(state, 'total', 0);
      } finally {
        _.set(state, 'loading', false);
        _.set(state, 'options.page', 0);
      }
    },
    async applyDateFilter(value) {
      let fromDate;
      let toDate;
      switch (value) {
        case 'All time':
          fromDate = moment('2020-01-01').utc().startOf('day');
          toDate = moment().utc().endOf('day');
          break;
        case '3 Months':
          fromDate = moment().utc().subtract(3, 'month').startOf('day');
          toDate = moment().utc().endOf('day');
          break;
        case '6 Months':
          fromDate = moment().utc().subtract(6, 'month').startOf('day');
          toDate = moment().utc().endOf('day');
          break;
        case 'This Year':
          fromDate = moment().utc().startOf('year');
          toDate = moment().utc().endOf('day');
          break;
        case 'Last Year':
          fromDate = moment().utc().subtract(1, 'year').startOf('year');
          toDate = moment().utc().subtract(1, 'year').endOf('year');
          break;
      }
      _.set(state, 'filters.dateRange.createdFrom', fromDate.toISOString());
      _.set(state, 'filters.dateRange.createdUpTo', toDate.toISOString());
    },
    async applyOptions(value) {
      let keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        _.set(state, `options.${key}`, value[key]);
      }
      if (!state.isInitialLoad) {
        await this.loadItems();
      }
    },
    getCurrency() {
      return currency;
    },
    async resetFilters() {
      state.filters = {};
      state.filters.dateRangeSelected = 'All time';
      state.filters.dateRange = {
        createdFrom: moment('2020-01-01').utc().startOf('day').toISOString(),
        createdUpTo: moment().utc().endOf('day').toISOString(),
      };
    },
    async resetIsInitialLoad() {
      state.isInitialLoad = true;
    },
    async resetItems() {
      state.items = [];
      state.total = 0;
    },
    async resetOptions() {
      state.options.page = 0;
      state.options.rowsPerPage = 20;
    },
    async resetInitialValues() {
      await this.resetFilters();
      await this.resetIsInitialLoad();
      await this.resetItems();
      await this.resetOptions();
    },
  };
  return { state, actions };
}
