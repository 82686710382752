import { reactive, computed } from '@vue/composition-api';

export default function Platform() {
  const platform = reactive({
    width: window ? window.innerWidth : 100,
    height: window ? window.innerHeight : 100,
    mobile: computed(() => platform.width < 1264),
    desktop: computed(() => platform.width >= 1264),
    dark: false, // false == "light" // light || dark
  });
  const actions = {
    async setup() {
      window.addEventListener('resize', () => {
        platform.width = window.innerWidth;
        platform.height = window.innerHeight;
      });
    },
  };
  actions.setup();
  return { platform, actions };
}
