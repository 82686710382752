<template>
  <v-app>
    <v-app-bar
      id="menu"
      app
      clipped-right
      clipped-left
      elevate-on-scroll
      v-if="$vuetify.breakpoint.mdAndUp && self.loaded"
      :style="$vuetify.theme.dark ? 'z-index: 9999;' : 'z-index: 9999;background-color:#fff'"
    >
      <div id="menuHeader">
        <v-img
          :src="
            $vuetify.theme.dark
              ? require('@/assets/logo_toro_white.svg')
              : require('@/assets/logo_toro_black.svg')
          "
          height="60"
          max-width="150"
          class="ma-0 pa-0 logo"
          contain
          @click="!state.authentication.forceTwoFASetup ? $router.push({ name: 'Dashboard' }) : ''"
        />
        <v-spacer />
        <div class="pa-1" id="bellButton">
          <pcc-button-tooltip icon="mdi-bell" position="bottom" />
        </div>
        <div class="pa-1" id="lightDarkButton">
          <pcc-button-tooltip
            v-on:click="darkMode()"
            icon="mdi-theme-light-dark"
            position="bottom"
            tooltip="Dark Mode"
          />
        </div>
        <v-chip
          id="userIcon"
          router
          @click="!state.authentication.forceTwoFASetup ? $router.push({ name: 'My' }) : ''"
          label
          class="mt-2 text--secondary"
          color="transparent"
        >
          <img
            :src="state.user.thumbnail ? state.user.thumbnail : require('@/assets/thumbnail.jpg')"
            width="100%"
            class="logo"
          />
        </v-chip>
        <v-btn @click="signOut()" text icon tile dense>
          <v-icon class="mt-1">power_settings_new</v-icon>
        </v-btn>
      </div>
      <div id="menuBoxed">
        <template v-for="item in state.routes">
          <v-chip
            v-if="!item.children"
            v-show="item.path !== 'my'"
            :key="item.path"
            router
            :to="{ name: item.name }"
            label
            class="mt-2 pa-5 text--secondary"
            color="transparent"
          >
            <v-icon left>{{ item.icon }}</v-icon>
            {{ item.path == 'my' ? state.user.name : item.name }}
          </v-chip>
          <template v-if="item.children">
            <v-menu
              :key="item.name"
              :open-on-hover="true"
              close-delay="333"
              offset-y
              nudge-width="233"
              nudge-top="-17"
              close-on-click
              :z-index="9999"
            >
              <template v-slot:activator="{ on }">
                <v-chip
                  router
                  v-on="on"
                  label
                  class="mt-2 pa-5 text--secondary"
                  :color="isChildSelected(item) ? '#1f8bc4' : 'transparent'"
                >
                  <v-icon left>{{ item.icon }}</v-icon>
                  <span :style="{ color: isChildSelected(item) ? '#fff' : '' }">{{
                    item.name
                  }}</span>
                </v-chip>
              </template>
              <v-card rounded class="ma-0 pa-0">
                <v-card-text class="ma-0 px-0 py-2">
                  <v-list dense>
                    <v-list-item
                      v-for="children in item.children"
                      :key="children.path"
                      :to="{ name: children.name }"
                      color="#1f8bc4"
                    >
                      <v-list-item-icon>
                        <v-icon v-html="children.icon" color="primary" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="children.title"
                          :style="{
                            'font-weight': 'normal',
                            color: $vuetify.theme.dark ? 'rgb(180 180 180)' : 'rgb(45 45 45)',
                          }"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </template>
      </div>
    </v-app-bar>
    <v-app-bar
      id="menuMobile"
      app
      clipped-right
      clipped-left
      elevate-on-scroll
      v-else-if="self.loaded"
      :style="$vuetify.theme.dark ? 'z-index: 9999;' : 'z-index: 9999;background-color:#fff'"
    >
      <v-img
        :src="
          $vuetify.theme.dark
            ? require('@/assets/logo_toro_white.svg')
            : require('@/assets/logo_toro_black.svg')
        "
        height="50"
        max-width="150"
        class="ma-0 pa-0"
        contain
        @click="$router.push({ name: 'Dashboard' })"
      />
      <v-spacer />
      <v-menu open-on-hover top offset-y nudge-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon large>apps</v-icon>
          </v-btn>
        </template>
        <v-list id="menuListMobile" dense nav>
          <template v-for="(item, i) in state.reducedRoutes">
            <v-row v-if="item.heading" :key="i" align="center">
              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn small text>edit</v-btn>
              </v-col>
            </v-row>
            <v-divider v-else-if="item.divider" :key="i" dark class="my-2 mx-2"></v-divider>
            <v-list-item v-else :key="i" router :to="{ name: item.name }">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.path == 'my' ? state.user.name : item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main :class="$vuetify.theme.dark ? 'background-gradient-dark' : 'background-gradient-light'">
      <v-container v-if="!self.loaded" class="fill-height mx-auto" fluid dark>
        <v-card
          class="transparent elevation-0 mx-auto"
          :width="$vuetify.breakpoint.smAndDown ? 400 : 600"
        >
          <v-img
            :src="
              $vuetify.theme.dark
                ? require('@/assets/logo_toro_white.svg')
                : require('@/assets/logo_toro_black.svg')
            "
            height="150"
            contain
          />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-progress-circular
              indeterminate
              width="1"
              size="100"
              color="grey"
            ></v-progress-circular>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-container>
      <router-view v-show="self.loaded"></router-view>
    </v-main>
    <v-footer app absolute color="transparent" elevation="0">
      <span class="caption text-capitalize font-weight-light ml-5">
        {{ new Date().getFullYear() }} © TORO Advertising
      </span>
      <v-spacer />
      <span class="caption text-capitalize font-weight-light mr-5">
        {{ state.user.currency }} | {{ date }}</span
      >
    </v-footer>
  </v-app>
</template>
<script>
import { inject, provide, watch, onMounted, reactive, ref } from '@vue/composition-api';
import PccButtonTooltip from './Components/PccButtonTooltip';
import PccButtonEditDelete from './Components/PccButtonEditDelete';
import PrivateApplication from '../store/PrivateApplication';
import Categories from '../store/private/Categories';
import PayoutTypes from '../store/private/PayoutTypes';
import Countries from '../store/private/Countries';
import Devices from '../store/private/Devices';
import Dashboard from '../store/private/Dashboard';
import Offers from '../store/private/Offers';
import Smartlinks from '../store/private/Smartlinks';
import Reports from '../store/private/Reports';
import ReportTS from '../store/private/ReportTS';
import Billing from '../store/private/Billing';
import Conversions from '../store/private/Conversions';
import Referral from '../store/private/Referral';

export default {
  components: {
    'pcc-button-tooltip': PccButtonTooltip,
    'pcc-button-edit-delete': PccButtonEditDelete,
  },
  props: {},
  setup(props, context) {
    const application = inject('Application');
    const currency = application.state.user.currency || 'USD';
    const _dashboard = Dashboard(currency);
    provide('PrivateApplication', PrivateApplication(currency));
    provide('Categories', Categories());
    provide('PayoutTypes', PayoutTypes());
    provide('Countries', Countries(currency));
    provide('Devices', Devices(currency));
    provide('Dashboard', _dashboard);
    provide('Offers', Offers(currency));
    provide('Smartlinks', Smartlinks(currency));
    provide('Reports', Reports(currency));
    provide('ReportTS', ReportTS(currency));
    provide('Billing', Billing(currency));
    provide('Conversions', Conversions(currency));
    provide('Referral', Referral(currency));
    const self = reactive({ loaded: false });
    const load = async (silent = false) => {
      await Promise.all([_dashboard.actions.loadAffiliateStatistics(silent)]);
    };
    onMounted(async () => {
      await load(false);
      setInterval(function () {
        load(true);
      }, 40000);
      self.loaded = true;
    });
    const date = ref(null);

    onMounted(async () => {
      setInterval(() => {
        date.value = new Date();
      }, 1000);
    });
    const signOut = () => {
      application.actions.singOut();
    };
    return { date, self, ...application, print, signOut };
  },
  methods: {
    isRouteSelected(route) {
      return this.$route.name === route.name;
    },
    isChildSelected(route) {
      return route.children.some((child) => this.$route.name === child.name);
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
    },
  },
  mounted() {
    const theme = localStorage.getItem('dark_theme');

    if (theme) {
      if (theme == 'true') {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
};
</script>
<style scoped>
.logo {
  cursor: pointer;
}
.app-bar {
  background-color: black;
}
</style>
