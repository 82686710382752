import { reactive } from '@vue/composition-api';
import Router from './Router';

export default function PrivateApplication() {
  const state = reactive({
    route: 'Dashboard',
  });
  const actions = {};

  Router.afterEach(async (to) => {
    state.route = to.name;
  });
  return { state, actions };
}
