import { reactive } from '@vue/composition-api';
import DeviceModel from '../constants/DeviceModel';

export default function Devices() {
  const state = reactive({
    DeviceBrandItems: DeviceModel.deviceBrand,
    DeviceBrandItemsLoading: false,
    DeviceClientItems: DeviceModel.deviceClient,
    DeviceClientItemsLoading: false,
    DeviceOsItems: DeviceModel.deviceOs,
    DeviceOsItemsLoading: false,
    DeviceTypeItems: DeviceModel.deviceType,
    DeviceTypeItemsLoading: false,
  });

  const actions = {};
  return { state, actions };
}
