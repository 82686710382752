import _ from 'lodash';
import { reactive, computed } from '@vue/composition-api';
import HttpClientV2 from '../HttpClientV2';
import Operation from '../constants/Operation';

export default function PayoutTypes() {
  const state = reactive({
    operation: Operation.NONE,
    items: [],
    total: 0,
    filters: {},
    loading: computed(() => state.operation != Operation.NONE),
  });

  const actions = {
    async loadItems() {
      try {
        const payoutTypeParams = {
          sortBy: 'name',
          limit: 300,
          page: 0,
          columns: ['_id', 'name'].join(','),
        };
        _.set(state, 'operation', Operation.IS_GETTING);
        let result = await HttpClientV2.callFunctionV2('GET', 'Payout-Type', payoutTypeParams);
        _.set(state, 'items', result.resultSet);
        _.set(state, 'total', result.total);
      } catch (error) {
        _.set(state, 'items', []);
        _.set(state, 'total', 0);
      } finally {
        _.set(state, 'operation', Operation.NONE);
      }
    },
  };
  return { state, actions };
}
