import _ from 'lodash';
import { reactive, computed } from '@vue/composition-api';
import HttpClient from '../HttpClient';
import HttpClientV2 from '../HttpClientV2';
import ItemState from '../constants/ItemState';
import Operation from '../constants/Operation';

const className = 'Category';

export default function Categories() {
  const state = reactive({
    operation: Operation.NONE,
    items: [],
    total: 0,
    filters: {},
    options: {
      rowsPerPage: 1000,
      page: 0,
    },
    item: null,
    itemState: ItemState.NOT_SELECTED,
    isEditing: false,
    isSelected: computed(() => state.itemState != ItemState.NOT_SELECTED),
    isModified: computed(() => [ItemState.DETACHED, ItemState.TRANSIENT].includes(state.itemState)),
    loading: computed(() => state.operation != Operation.NONE),
  });

  const actions = {
    async applyFilter({ key, value }) {
      if (_.isEmpty(value)) {
        delete state['filters'][key];
      } else {
        _.set(state, `filters.${key}`, value);
      }
      await this.loadItems({});
    },
    async removeFilter({ key }) {
      delete state['filters'][key];
      await this.loadItems({});
    },
    async applyOptions({ value }) {
      _.set(state, 'options', value);
      await this.loadItems({});
    },
    async loadItems() {
      try {
        const categoryParams = {
          sortBy: 'name',
          limit: 300,
          page: 0,
          columns: ['_id', 'name'].join(','),
        };
        let result = await HttpClientV2.callFunctionV2('GET', 'Category', categoryParams);
        _.set(state, 'items', _.sortBy(result.resultSet, ['name']));
        _.set(state, 'total', result.total);
      } catch (error) {
        _.set(state, 'items', []);
        _.set(state, 'total', 0);
      } finally {
        _.set(state, 'operation', Operation.NONE);
      }
    },
    async selectItem({ value }) {
      _.set(state, 'item', value);
      _.set(state, 'itemState', ItemState.PERSISTENT);
    },
    async unselectItem() {
      _.set(state, 'item', null);
      _.set(state, 'isEditing', false);
      _.set(state, 'itemState', ItemState.NOT_SELECTED);
    },
    async newItem(item = {}) {
      _.set(state, 'item', item);
      _.set(state, 'isEditing', true);
      _.set(state, 'itemState', ItemState.TRANSIENT);
    },
    async editItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      let itemState = _.get(state, 'itemState');
      if ([ItemState.PERSISTENT, ItemState.TRANSIENT, ItemState.DETACHED].includes(itemState)) {
        _.set(state, 'isEditing', true);
      } else {
        throw new Error('The item must be in the PERSISTENT, TRANSIENT or DETACHED state to edit.');
      }
    },
    async saveItem() {
      let itemState = _.get(state, 'itemState');
      if (itemState == ItemState.TRANSIENT) {
        try {
          _.set(state, 'operation', Operation.IS_CREATING);
          let result = await HttpClient.runProcess(className, 'create_one', {
            payload: _.get(state, 'item'),
          });
          _.set(state, 'item', result);
          _.set(state, 'itemState', ItemState.PERSISTENT);
        } catch (error) {
          // Why this is empty?
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else if (itemState == ItemState.DETACHED) {
        try {
          _.set(state, 'operation', Operation.IS_UPDATING);
          let result = await HttpClient.runProcess(className, 'update_one', {
            payload: _.get(state, 'item'),
          });
          _.set(state, 'item', result);
          _.set(state, 'itemState', ItemState.PERSISTENT);
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the TRANSIENT or DETACHED state to save.');
      }
    },
    async deleteItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      let itemState = _.get(state, 'itemState');
      if (itemState == ItemState.PERSISTENT || itemState == ItemState.DETACHED) {
        try {
          _.set(state, 'operation', Operation.IS_DELETING);
          await HttpClient.runProcess(className, 'delete_one', { payload: _.get(state, 'item') });
          await this.unselectItem({});
          await this.loadItems({});
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the PERSISTENT or DETACHED state to delete.');
      }
    },
    async updateAttribute({ key, value }) {
      _.set(state, `${key}`, value);
    },
    async updateItemAttribute({ key, value }) {
      _.set(state, `item.${key}`, value);
      await this.markAsModified({});
    },
    async markAsModified() {
      let itemState = _.get(state, 'itemState');
      if (itemState != ItemState.TRANSIENT) _.set(state, 'itemState', ItemState.DETACHED);
    },
  };
  return { state, actions };
}
