import _ from 'lodash';
import { reactive, computed } from '@vue/composition-api';
import HttpClientV2 from '../HttpClientV2';
import moment from 'moment-timezone';
import ItemState from '../constants/ItemState';
import Operation from '../constants/Operation';

export default function Conversions(currency) {
  const state = reactive({
    pagination: {
      itemsPerPage: 20,
      page: 1,
      sortDesc: [false],
      sortBy: ['_id'],
    },
    symbol: computed(() => (currency == 'USD' ? '$' : '€')),
    operation: Operation.NONE,
    items: [],
    total: 0,
    offers: [],
    isFetchingOfferList: true,
    countries: [],
    exportId: '',
    exportOwner: '',
    exportUrl: '',
    filters: {
      dateRange: {
        converted: true,
        fromDate: moment().startOf('day').tz('GMT', true),
        toDate: moment().endOf('day').tz('GMT', true),
      },
    },
    selectedFilters: {},
    options: {
      groupBy: 'day',
      headers: [],
    },
    calculations: [
      {
        name: 'Payout',
        value: `payout${currency}`,
      },

      {
        name: 'Conversions',
        value: 'conversions',
      },
    ],
    headers: [
      {
        text: 'Created At',
        aling: 'center',
        value: 'createdAt',
        sortable: true,
        width: 100,
      },
      {
        text: 'Conversion ID',
        aling: 'center',
        value: '_id',
        sortable: false,
        width: 100,
      },
      {
        text: 'Status',
        aling: 'center',
        value: 'status',
        width: 100,
        sortable: true,
      },

      {
        text: 'Country',
        aling: 'center',
        value: 'countryName',
        width: 100,
        sortable: true,
      },
      {
        text: 'Offer ID',
        aling: 'center',
        value: '_offer',
        width: 100,
        sortable: false,
      },
      {
        text: 'Offer Name',
        aling: 'center',
        value: 'offerName',
        width: 100,
        sortable: true,
      },

      {
        text: 'Payout',
        aling: 'center',
        value: `payout${currency}`,
        sortable: true,
        width: 100,
      },
    ],
    item: null,
    csvHeaders: [
      'Day',
      'Hour',
      'Conversion ID',
      'Status',
      'Event',
      'Offer',
      'Offer ID',
      'Smartlink Name',
      'Smartlink ID',
      `Payout ${currency}`,
      'Country',
    ],
    csvHeadersModal: false,
    application: null,
    itemState: ItemState.NOT_SELECTED,
    isEditing: false,
    isSelected: computed(() => state.itemState != ItemState.NOT_SELECTED),
    isModified: computed(() => [ItemState.DETACHED, ItemState.TRANSIENT].includes(state.itemState)),
    loading: computed(() => state.operation != Operation.NONE),
    showTotals: false,
    conversionsMeta: [],
    totals: {},
    csvUrl: '',
    initialState: true,
    initialStatePagination: true,
    exportCsv: true,
    doneCsv: false,
    errorCsv: false,
    exportingCsv: false,
    errorDescriptionCsv: '',
    showErrorDescriptionCsv: false,
    loadingOffers: false,
    loadingCountries: false,
    isLoadingOffers: computed(() => state.loadingOffers),
    isLoadingCountries: computed(() => state.loadingCountries),
    isLoadingRunReportButton: computed(() => state.loading || state.exportingCsv),
    showExportButton: computed(() => !state.doneCsv && !state.errorCsv),
    disableExportButton: computed(
      () => state.initialState || state.loading || state.exportingCsv || !state.items.length
    ),
    showExportReport: computed(() => state.exportCsv && !state.exportingCsv),
    showExportingReport: computed(() => state.exportingCsv),
    showDownloadButton: computed(() => state.doneCsv && !state.errorCsv),
  });

  const actions = {
    async resetCsvValues() {
      _.set(state, 'exportCsv', true);
      _.set(state, 'errorCsv', false);
      _.set(state, 'doneCsv', false);
      _.set(state, 'showErrorDescriptionCsv', false);
    },
    async applyFilter({ key, value }) {
      this.resetCsvValues();
      if (_.isNil(value) || _.isEmpty(value)) {
        _.unset(state, `filters.${key}`);
      } else {
        _.set(state, `filters.${key}`, value);
      }
    },
    async resetFilters() {
      state.filters = {};
      state.filters.dateRange = {
        fromDate: moment().startOf('day').tz('GMT', true),
        toDate: moment().endOf('day').tz('GMT', true),
      };
    },
    async removeFilter({ key }) {
      this.resetCsvValues();
      delete state['filters'][key];
      await this.loadItems({});
    },
    async applyOptions({ value }) {
      let keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        _.set(state, `options.${key}`, value[key]);
      }

      await this.loadItems();
    },
    async applyHeadersOptions({ value }) {
      _.set(state, 'options.headers', value);
    },
    async applyGroupBy({ value }) {
      this.resetCsvValues();
      _.set(state, 'options.groupBy', value);
    },
    async applyHeaders({ value }) {
      this.resetCsvValues();
      _.set(state, 'headers', value);
    },
    async applyCalculations({ value }) {
      _.set(state, 'calculations', value);
    },
    affiliateCurrency() {
      return currency;
    },
    setCsvHeaders(selectedCsvHeaders) {
      _.set(state, 'csvHeaders', selectedCsvHeaders);
    },
    showCsvHeadersModal() {
      _.set(state, 'csvHeadersModal', true);
    },
    hideCsvHeadersModal() {
      _.set(state, 'csvHeadersModal', false);
    },
    async loadItems() {
      this.resetCsvValues();
      if (_.isEqual(_.get(state, 'operation'), Operation.NONE)) {
        _.set(state, 'initialStatePagination', false);
        _.set(state, 'initialState', false);
        try {
          _.set(state, 'operation', Operation.IS_GETTING);

          let filters = Object.keys(state.filters).length > 0 ? state.filters : {};
          let createdFrom = state.filters.dateRange.fromDate;
          let createdUpTo = state.filters.dateRange.toDate;
          if (!createdUpTo) {
            createdUpTo = moment().endOf('day').tz('GMT', true).toISOString();
            createdFrom = createdFrom.toISOString();
          }
          const normalizedFilters = _.cloneDeep(filters);
          delete normalizedFilters.dateRange;
          let result = await HttpClientV2.callFunctionV2('GET', 'report/affiliateConversions', {
            ...normalizeFiltersV2(normalizedFilters),
            createdUpTo,
            createdFrom,
            page: state.pagination.page - 1,
            limit: state.pagination.itemsPerPage,
            sortBy: state.pagination.sortBy.length ? state.pagination.sortBy[0] : null,
            descending: state.pagination.sortDesc.length ? state.pagination.sortDesc[0] : null,
          });
          _.set(state, 'items', result.resultSet);
          _.set(state, 'total', result.total);
          _.set(state, 'totals', result.meta);
          this.setShowTotals(true);
        } catch (error) {
          _.set(state, 'items', []);
          _.set(state, 'total', 0);
        } finally {
          _.set(state, 'options.page', 0);
          _.set(state, 'operation', Operation.NONE);
        }
      }
    },
    async loadFilterItems() {
      state.loadingOffers = true;
      const offerParams = {
        limit: 0,
        page: 0,
        columns: '_id,name',
        disablePagination: 'true',
      };
      const offers = await HttpClientV2.callFunctionV2('GET', 'offer', offerParams);
      state.offers = offers.resultSet;
      state.loadingOffers = false;

      state.loadingCountries = true;
      const countryParams = {
        limit: 1000,
        page: 0,
        sortBy: 'name',
        descending: -1,
      };
      const result = await HttpClientV2.callFunctionV2('GET', 'country', countryParams);
      state.countries = _.sortBy(
        result.resultSet.filter((l) => !_.isEmpty(l.name)),
        'name'
      );
      state.loadingCountries = false;
    },
    async downloadButtonHandler() {
      _.set(state, 'initialState', true);
      _.set(state, 'csvUrl', '');
      _.set(state, 'doneCsv', false);
      _.set(state, 'exportingCsv', false);
      _.set(state, 'exportCsv', true);
    },
    async selectItem({ value }) {
      _.set(state, 'item', value);
      _.set(state, 'itemState', ItemState.PERSISTENT);
    },
    async unselectItem() {
      _.set(state, 'application', null);
      _.set(state, 'item', null);
      _.set(state, 'isEditing', false);
      _.set(state, 'itemState', ItemState.NOT_SELECTED);
      _.set(state, 'operation', Operation.NONE);
      await this.loadItems();
    },
    async newItem(item = {}) {
      _.set(state, 'item', item);
      _.set(state, 'isEditing', true);
      _.set(state, 'itemState', ItemState.TRANSIENT);
    },
    async updateAttribute({ key, value }) {
      _.set(state.selectedObject, `${key}`, value);
    },
    async updateItemAttribute({ key, value }) {
      _.set(state, `item.${key}`, value);
      await this.markAsModified({});
    },
    async markAsModified() {
      let itemState = _.get(state, 'itemState');
      if (itemState != ItemState.TRANSIENT) _.set(state, 'itemState', ItemState.DETACHED);
    },
    async setShowTotals(value) {
      if (value === true) {
        _.set(state, 'showTotals', true);
      }
    },
    async exportCsv() {
      if (_.isEqual(_.get(state, 'operation'), Operation.NONE)) {
        try {
          _.set(state, 'operation', Operation.IS_GETTING);
          _.set(state, 'exportingCsv', true);
          _.set(state, 'exportCsv', false);
          let filters = Object.keys(state.filters).length > 0 ? state.filters : {};
          let createdFrom = state.filters.dateRange.fromDate;
          let createdUpTo = state.filters.dateRange.toDate;
          if (!createdUpTo) {
            createdUpTo = moment().endOf('day').tz('GMT', true).toISOString();
            createdFrom = createdFrom.toISOString();
          }
          const normalizedFilters = _.cloneDeep(filters);
          delete normalizedFilters.dateRange;
          const result = await HttpClientV2.callFunctionV2(
            'POST',
            'conversion/exportAffiliateConversions',
            {
              ...normalizeFiltersV2(normalizedFilters),
              createdUpTo,
              createdFrom,
              csvHeaders: state.csvHeaders,
            }
          );
          _.set(state, 'exportId', result.data._id);
        } catch (error) {
          console.log(error);
        } finally {
          this.findExportProcess();
        }
      }
    },
    async findExportProcess() {
      try {
        _.set(state, 'operation', Operation.IS_GETTING);
        let result = await HttpClientV2.callFunctionV2(
          'GET',
          `exportService/${state.exportId}`,
          null
        );
        let status = result.status;
        let error = result.errorDescription;
        while (status === 'PENDING') {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          result = await HttpClientV2.callFunctionV2(
            'GET',
            `exportService/${state.exportId}`,
            null
          );
          status = result.status;
        }
        if (status === 'DONE') {
          _.set(state, 'csvUrl', result.url);
        } else if (status === 'ERROR') {
          _.set(state, 'errorCsv', true);
          _.set(state, 'errorDescriptionCsv', error);
          _.set(state, 'showErrorDescriptionCsv', true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        _.set(state, 'doneCsv', true);
        _.set(state, 'exportingCsv', false);
        _.set(state, 'operation', Operation.NONE);
      }
    },
  };
  return { state, actions };
}

function normalizeFiltersV2(filters) {
  for (const prop in filters) {
    if (Array.isArray(filters[prop])) {
      filters[prop] = filters[prop].join(',');
    }
  }
  return filters;
}
