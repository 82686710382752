<template>
  <router-view></router-view>
</template>
<script>
import { provide, onMounted } from '@vue/composition-api';

export default {
  setup() {
    const Application = require('../store/Application');
    provide('Application', Application);
    onMounted(() => {
      Application.actions.setup();
    });
    return {};
  },
};
</script>
<style lang="scss">
@import '@/styles/index.scss';
@import '@/styles/indexMobile.scss';
</style>
