import browserShort from 'node-device-detector/parser/client/browser-short';

export default {
  deviceClientBrowser: Object.keys(browserShort)
    .map((key) => ({ _id: key, name: browserShort[key] }))
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }),
  deviceOs: [
    'Android',
    'AmigaOS',
    'Apple TV',
    'BlackBerry',
    'Brew',
    'BeOS',
    'Chrome OS',
    'Firefox OS',
    'Gaming Console',
    'Google TV',
    'IBM',
    'iOS',
    'RISC OS',
    'GNU/Linux',
    'Mac',
    'Mobile Gaming Console',
    'Real-time OS',
    'Other Mobile',
    'Symbian',
    'Unix',
    'WebTV',
    'Windows',
    'Windows Mobile',
  ],
  deviceClient: ['mobile app', 'browser', 'library', 'mediaplayer', 'feed reader', 'pim'],
  deviceType: [
    'desktop',
    'smartphone',
    'tablet',
    'feature phone',
    'console',
    'tv',
    'car browser',
    'smart display',
    'camera',
    'portable media player',
    'phablet',
    'smart speaker',
    'wearable',
  ],
  deviceBrand: [
    { _id: '36', name: '360' },
    { _id: '3Q', name: '3Q' },
    { _id: '4G', name: '4Good' },
    { _id: 'AE', name: 'Ace' },
    { _id: 'AA', name: 'AllCall' },
    { _id: 'AC', name: 'Acer' },
    { _id: '00', name: 'Accent' },
    { _id: 'A9', name: 'Advan' },
    { _id: 'AD', name: 'Advance' },
    { _id: 'A3', name: 'AGM' },
    { _id: 'AZ', name: 'Ainol' },
    { _id: 'AI', name: 'Airness' },
    { _id: '0A', name: 'AIS' },
    { _id: 'AW', name: 'Aiwa' },
    { _id: 'AK', name: 'Akai' },
    { _id: '1A', name: 'Alba' },
    { _id: 'AL', name: 'Alcatel' },
    { _id: '4A', name: 'Aligator' },
    { _id: '3A', name: 'AllDocube' },
    { _id: 'A2', name: 'Allview' },
    { _id: 'A7', name: 'Allwinner' },
    { _id: 'A1', name: 'Altech UEC' },
    { _id: 'A5', name: 'altron' },
    { _id: '3L', name: 'Alfawise' },
    { _id: 'AN', name: 'Arnova' },
    { _id: '7A', name: 'Anry' },
    { _id: '5A', name: 'ArmPhone' },
    { _id: '2A', name: 'Atom' },
    { _id: 'KN', name: 'Amazon' },
    { _id: 'AG', name: 'AMGOO' },
    { _id: '9A', name: 'Amigoo' },
    { _id: 'AO', name: 'Amoi' },
    { _id: '3N', name: 'Aoson' },
    { _id: 'AP', name: 'Apple' },
    { _id: 'AR', name: 'Archos' },
    { _id: 'AS', name: 'ARRIS' },
    { _id: 'AB', name: 'Arian Space' },
    { _id: 'AT', name: 'Airties' },
    { _id: '6A', name: 'AT&T' },
    { _id: 'A6', name: 'Ark' },
    { _id: 'A4', name: 'Ask' },
    { _id: '8A', name: 'Asano' },
    { _id: 'A8', name: 'Assistant' },
    { _id: 'A0', name: 'ANS' },
    { _id: 'AU', name: 'Asus' },
    { _id: 'AH', name: 'AVH' },
    { _id: 'ZA', name: 'Avenzo' },
    { _id: 'AV', name: 'Avvio' },
    { _id: 'AX', name: 'Audiovox' },
    { _id: 'AY', name: 'Axxion' },
    { _id: 'AM', name: 'Azumi Mobile' },
    { _id: 'BB', name: 'BBK' },
    { _id: '0B', name: 'BB Mobile' },
    { _id: 'BE', name: 'Becker' },
    { _id: 'B5', name: 'Beeline' },
    { _id: 'B0', name: 'Beelink' },
    { _id: 'BI', name: 'Bird' },
    { _id: '1B', name: 'Billion' },
    { _id: 'BT', name: 'Bitel' },
    { _id: 'B8', name: 'BIHEE' },
    { _id: 'B7', name: 'Bitmore' },
    { _id: 'BG', name: 'BGH' },
    { _id: 'BL', name: 'Beetel' },
    { _id: 'BP', name: 'Blaupunkt' },
    { _id: 'B3', name: 'Bluboo' },
    { _id: 'BF', name: 'Black Fox' },
    { _id: 'B6', name: 'BDF' },
    { _id: 'BM', name: 'Bmobile' },
    { _id: 'BN', name: 'Barnes & Noble' },
    { _id: 'BO', name: 'BangOlufsen' },
    { _id: 'B9', name: 'Bobarry' },
    { _id: 'BQ', name: 'BenQ' },
    { _id: 'BS', name: 'BenQ-Siemens' },
    { _id: 'BU', name: 'Blu' },
    { _id: 'BD', name: 'Bluegood' },
    { _id: 'B2', name: 'Blackview' },
    { _id: 'B4', name: 'bogo' },
    { _id: 'BW', name: 'Boway' },
    { _id: 'BZ', name: 'Bezkam' },
    { _id: 'BX', name: 'bq' },
    { _id: 'BV', name: 'Bravis' },
    { _id: 'BR', name: 'Brondi' },
    { _id: 'B1', name: 'Bush' },
    { _id: 'CB', name: 'CUBOT' },
    { _id: 'CF', name: 'Carrefour' },
    { _id: 'CP', name: 'Captiva' },
    { _id: 'CS', name: 'Casio' },
    { _id: 'R4', name: 'Casper' },
    { _id: 'CA', name: 'Cat' },
    { _id: 'C9', name: 'CAGI' },
    { _id: 'CE', name: 'Celkon' },
    { _id: 'CC', name: 'ConCorde' },
    { _id: 'C2', name: 'Changhong' },
    { _id: '2C', name: 'Ghong' },
    { _id: 'CH', name: 'Cherry Mobile' },
    { _id: '1C', name: 'Chuwi' },
    { _id: 'L8', name: 'Clarmin' },
    { _id: 'CD', name: 'Cloudfone' },
    { _id: 'C0', name: 'Clout' },
    { _id: 'CK', name: 'Cricket' },
    { _id: 'C1', name: 'Crosscall' },
    { _id: 'CL', name: 'Compal' },
    { _id: 'CN', name: 'CnM' },
    { _id: 'CM', name: 'Crius Mea' },
    { _id: 'C3', name: 'China Mobile' },
    { _id: 'CR', name: 'CreNova' },
    { _id: '0C', name: 'Crony' },
    { _id: 'CT', name: 'Capitel' },
    { _id: 'CQ', name: 'Compaq' },
    { _id: '3C', name: 'Contixo' },
    { _id: 'CO', name: 'Coolpad' },
    { _id: 'C5', name: 'Condor' },
    { _id: 'CW', name: 'Cowon' },
    { _id: 'CU', name: 'Cube' },
    { _id: 'CY', name: 'Coby Kyros' },
    { _id: 'C6', name: 'Comio' },
    { _id: 'C7', name: 'ComTrade Tesla' },
    { _id: 'C8', name: 'Concord' },
    { _id: 'CX', name: 'Crescent' },
    { _id: 'C4', name: 'Cyrus' },
    { _id: 'CV', name: 'CVTE' },
    { _id: 'D5', name: 'Daewoo' },
    { _id: 'DA', name: 'Danew' },
    { _id: 'DT', name: 'Datang' },
    { _id: 'D7', name: 'Datawind' },
    { _id: 'D1', name: 'Datsun' },
    { _id: 'DE', name: 'Denver' },
    { _id: 'DW', name: 'DeWalt' },
    { _id: 'DX', name: 'DEXP' },
    { _id: 'DS', name: 'Desay' },
    { _id: 'DB', name: 'Dbtel' },
    { _id: 'DC', name: 'DoCoMo' },
    { _id: 'D9', name: 'Dolamee' },
    { _id: 'D0', name: 'Doopro' },
    { _id: 'DG', name: 'Dialog' },
    { _id: 'DI', name: 'Dicam' },
    { _id: 'D4', name: 'Digi' },
    { _id: 'D3', name: 'Digicel' },
    { _id: 'DD', name: 'Digiland' },
    { _id: 'D2', name: 'Digma' },
    { _id: 'D6', name: 'Divisat' },
    { _id: 'DL', name: 'Dell' },
    { _id: 'DN', name: 'DNS' },
    { _id: 'DM', name: 'DMM' },
    { _id: 'DO', name: 'Doogee' },
    { _id: 'DF', name: 'Doffler' },
    { _id: 'DV', name: 'Doov' },
    { _id: 'DP', name: 'Dopod' },
    { _id: 'DR', name: 'Doro' },
    { _id: 'D8', name: 'Droxio' },
    { _id: 'DU', name: 'Dune HD' },
    { _id: 'EB', name: 'E-Boda' },
    { _id: '2E', name: 'E-Ceros' },
    { _id: '5E', name: '2E' },
    { _id: 'EA', name: 'EBEST' },
    { _id: 'EC', name: 'Ericsson' },
    { _id: 'E7', name: 'Ergo' },
    { _id: 'ED', name: 'Energizer' },
    { _id: 'E4', name: 'Echo Mobiles' },
    { _id: 'ES', name: 'ECS' },
    { _id: 'E6', name: 'EE' },
    { _id: 'EI', name: 'Ezio' },
    { _id: 'EM', name: 'Eks Mobility' },
    { _id: 'EL', name: 'Elephone' },
    { _id: '4E', name: 'Eltex' },
    { _id: 'L0', name: 'Element' },
    { _id: 'EG', name: 'Elenberg' },
    { _id: 'EP', name: 'Easypix' },
    { _id: 'EK', name: 'EKO' },
    { _id: 'E1', name: 'Energy Sistem' },
    { _id: '3E', name: 'Enot' },
    { _id: 'ER', name: 'Ericy' },
    { _id: 'EE', name: 'Essential' },
    { _id: 'EN', name: 'Eton' },
    { _id: 'E2', name: 'Essentielb' },
    { _id: '1E', name: 'Etuline' },
    { _id: 'ET', name: 'eTouch' },
    { _id: 'EV', name: 'Evertek' },
    { _id: 'E3', name: 'Evolio' },
    { _id: 'EO', name: 'Evolveo' },
    { _id: 'EX', name: 'Explay' },
    { _id: 'E0', name: 'EvroMedia' },
    { _id: 'E5', name: 'Extrem' },
    { _id: 'EZ', name: 'Ezze' },
    { _id: 'E8', name: 'E-tel' },
    { _id: 'E9', name: 'Evercoss' },
    { _id: 'EU', name: 'Eurostar' },
    { _id: 'FA', name: 'Fairphone' },
    { _id: 'FM', name: 'Famoco' },
    { _id: 'FE', name: 'Fengxiang' },
    { _id: 'FI', name: 'FiGO' },
    { _id: 'F3', name: 'FireFly Mobile' },
    { _id: 'FL', name: 'Fly' },
    { _id: 'F1', name: 'FinePower' },
    { _id: 'FT', name: 'Freetel' },
    { _id: 'FR', name: 'Forstar' },
    { _id: 'FO', name: 'Foxconn' },
    { _id: 'F2', name: 'FORME' },
    { _id: 'FN', name: 'FNB' },
    { _id: 'FU', name: 'Fujitsu' },
    { _id: 'FD', name: 'Fondi' },
    { _id: 'GT', name: 'G-TiDE' },
    { _id: 'GM', name: 'Garmin-Asus' },
    { _id: 'GA', name: 'Gateway' },
    { _id: 'GD', name: 'Gemini' },
    { _id: 'GN', name: 'General Mobile' },
    { _id: 'GE', name: 'Geotel' },
    { _id: 'GH', name: 'Ghia' },
    { _id: 'GI', name: 'Gionee' },
    { _id: 'GG', name: 'Gigabyte' },
    { _id: 'GS', name: 'Gigaset' },
    { _id: 'GZ', name: 'Ginzzu' },
    { _id: 'G4', name: 'Globex' },
    { _id: 'GC', name: 'GOCLEVER' },
    { _id: 'GL', name: 'Goly' },
    { _id: 'GO', name: 'Google' },
    { _id: 'G5', name: 'Gome' },
    { _id: 'G1', name: 'GoMobile' },
    { _id: 'GR', name: 'Gradiente' },
    { _id: 'GP', name: 'Grape' },
    { _id: 'G6', name: 'Gree' },
    { _id: 'G0', name: 'Goophone' },
    { _id: 'GU', name: 'Grundig' },
    { _id: 'HF', name: 'Hafury' },
    { _id: 'HA', name: 'Haier' },
    { _id: 'HS', name: 'Hasee' },
    { _id: 'HE', name: 'HannSpree' },
    { _id: 'HI', name: 'Hisense' },
    { _id: 'HL', name: 'Hi-Level' },
    { _id: 'H2', name: 'Highscreen' },
    { _id: 'H1', name: 'Hoffmann' },
    { _id: 'HM', name: 'Homtom' },
    { _id: 'HO', name: 'Hosin' },
    { _id: 'HW', name: 'How' },
    { _id: 'HV', name: 'Hotwav' },
    { _id: 'HZ', name: 'Hoozo' },
    { _id: 'HP', name: 'HP' },
    { _id: 'HT', name: 'HTC' },
    { _id: 'HD', name: 'Huadoo' },
    { _id: 'HU', name: 'Huawei' },
    { _id: 'HX', name: 'Humax' },
    { _id: 'HY', name: 'Hyrican' },
    { _id: 'HN', name: 'Hyundai' },
    { _id: 'IG', name: 'iGet' },
    { _id: 'IA', name: 'Ikea' },
    { _id: 'IB', name: 'iBall' },
    { _id: '3I', name: 'i-Cherry' },
    { _id: 'IJ', name: 'i-Joy' },
    { _id: 'IC', name: 'iDroid' },
    { _id: 'IY', name: 'iBerry' },
    { _id: 'IH', name: 'iHunt' },
    { _id: 'IK', name: 'iKoMo' },
    { _id: 'IE', name: 'iView' },
    { _id: 'I8', name: 'iVA' },
    { _id: '1I', name: 'iMars' },
    { _id: 'IM', name: 'i-mate' },
    { _id: '2I', name: 'iLife' },
    { _id: 'I1', name: 'iOcean' },
    { _id: 'I2', name: 'IconBIT' },
    { _id: 'IL', name: 'IMO Mobile' },
    { _id: 'I7', name: 'iLA' },
    { _id: 'IW', name: 'iNew' },
    { _id: 'IP', name: 'iPro' },
    { _id: 'IF', name: 'Infinix' },
    { _id: 'I0', name: 'InFocus' },
    { _id: 'I5', name: 'InnJoo' },
    { _id: 'IN', name: 'Innostream' },
    { _id: 'IS', name: 'Insignia' },
    { _id: 'I4', name: 'Inoi' },
    { _id: 'IR', name: 'iRola' },
    { _id: 'IU', name: 'iRulu' },
    { _id: 'I6', name: 'Irbis' },
    { _id: 'II', name: 'Inkti' },
    { _id: 'IX', name: 'Intex' },
    { _id: '4I', name: 'Invin' },
    { _id: 'IO', name: 'i-mobile' },
    { _id: 'IQ', name: 'INQ' },
    { _id: '8Q', name: 'IQM' },
    { _id: 'IT', name: 'Intek' },
    { _id: 'IV', name: 'Inverto' },
    { _id: 'I3', name: 'Impression' },
    { _id: 'IZ', name: 'iTel' },
    { _id: '0I', name: 'iTruck' },
    { _id: 'I9', name: 'iZotron' },
    { _id: 'JA', name: 'JAY-Tech' },
    { _id: 'JI', name: 'Jiayu' },
    { _id: 'JG', name: 'Jinga' },
    { _id: 'JO', name: 'Jolla' },
    { _id: 'J5', name: 'Just5' },
    { _id: 'JF', name: 'JFone' },
    { _id: 'JK', name: 'JKL' },
    { _id: 'KL', name: 'Kalley' },
    { _id: '0K', name: 'Klipad' },
    { _id: 'K4', name: 'Kaan' },
    { _id: 'K7', name: 'Kaiomy' },
    { _id: 'K6', name: 'Kanji' },
    { _id: 'KA', name: 'Karbonn' },
    { _id: 'K5', name: 'KATV1' },
    { _id: 'KD', name: 'KDDI' },
    { _id: 'K1', name: 'Kiano' },
    { _id: 'KV', name: 'Kivi' },
    { _id: 'KI', name: 'Kingsun' },
    { _id: 'KC', name: 'Kocaso' },
    { _id: 'KG', name: 'Kogan' },
    { _id: 'KO', name: 'Konka' },
    { _id: 'KM', name: 'Komu' },
    { _id: 'KB', name: 'Koobee' },
    { _id: 'K9', name: 'Kooper' },
    { _id: 'KT', name: 'K-Touch' },
    { _id: 'KH', name: 'KT-Tech' },
    { _id: 'KK', name: 'Kodak' },
    { _id: 'KP', name: 'KOPO' },
    { _id: 'KW', name: 'Konrow' },
    { _id: 'KR', name: 'Koridy' },
    { _id: 'K2', name: 'KRONO' },
    { _id: 'KS', name: 'Kempler & Strauss' },
    { _id: 'K3', name: 'Keneksi' },
    { _id: 'K8', name: 'Kuliao' },
    { _id: 'KU', name: 'Kumai' },
    { _id: 'KY', name: 'Kyocera' },
    { _id: 'KZ', name: 'Kazam' },
    { _id: '1K', name: 'Kzen' },
    { _id: 'KE', name: 'Krüger&Matz' },
    { _id: 'KX', name: 'Kenxinda' },
    { _id: 'LQ', name: 'LAIQ' },
    { _id: 'L2', name: 'Landvo' },
    { _id: 'L6', name: 'Land Rover' },
    { _id: 'LV', name: 'Lava' },
    { _id: 'LA', name: 'Lanix' },
    { _id: 'LK', name: 'Lark' },
    { _id: 'LC', name: 'LCT' },
    { _id: 'L5', name: 'Leagoo' },
    { _id: 'LD', name: 'Ledstar' },
    { _id: 'L1', name: 'LeEco' },
    { _id: 'L4', name: 'Lemhoov' },
    { _id: 'LE', name: 'Lenovo' },
    { _id: 'LN', name: 'Lenco' },
    { _id: 'LT', name: 'Leotec' },
    { _id: 'L7', name: 'Lephone' },
    { _id: 'LZ', name: 'Lesia' },
    { _id: 'LP', name: 'Le Pan' },
    { _id: 'LG', name: 'LG' },
    { _id: 'LI', name: 'Lingwin' },
    { _id: 'LO', name: 'Loewe' },
    { _id: 'LM', name: 'Logicom' },
    { _id: '1L', name: 'Logic' },
    { _id: 'L3', name: 'Lexand' },
    { _id: 'LX', name: 'Lexibook' },
    { _id: 'LY', name: 'LYF' },
    { _id: 'LU', name: 'Lumus' },
    { _id: '0L', name: 'Lumigon' },
    { _id: 'L9', name: 'Luna' },
    { _id: 'MN', name: 'M4tel' },
    { _id: 'XM', name: 'Macoox' },
    { _id: 'MJ', name: 'Majestic' },
    { _id: 'MA', name: 'Manta Multimedia' },
    { _id: '6X', name: 'Maxtron' },
    { _id: '5M', name: 'Mann' },
    { _id: '2M', name: 'Masstel' },
    { _id: 'MW', name: 'Maxwest' },
    { _id: '7M', name: 'Maxcom' },
    { _id: '0D', name: 'MAXVI' },
    { _id: 'M0', name: 'Maze' },
    { _id: 'MB', name: 'Mobistel' },
    { _id: '9M', name: 'Mobo' },
    { _id: '0M', name: 'Mecool' },
    { _id: 'M3', name: 'Mecer' },
    { _id: 'MD', name: 'Medion' },
    { _id: 'M2', name: 'MEEG' },
    { _id: 'M1', name: 'Meizu' },
    { _id: '3M', name: 'Meitu' },
    { _id: 'ME', name: 'Metz' },
    { _id: '09', name: 'meanIT' },
    { _id: '0E', name: 'Melrose' },
    { _id: 'MX', name: 'MEU' },
    { _id: 'MI', name: 'MicroMax' },
    { _id: '8M', name: 'Mito' },
    { _id: '1X', name: 'Minix' },
    { _id: 'M5', name: 'MIXC' },
    { _id: '1Z', name: 'MiXzo' },
    { _id: 'MH', name: 'Mobiola' },
    { _id: '4M', name: 'Mobicel' },
    { _id: 'M6', name: 'Mobiistar' },
    { _id: 'MC', name: 'Mediacom' },
    { _id: 'MK', name: 'MediaTek' },
    { _id: 'MO', name: 'Mio' },
    { _id: 'M7', name: 'Miray' },
    { _id: 'MM', name: 'Mpman' },
    { _id: 'LS', name: 'MLS' },
    { _id: 'M4', name: 'Modecom' },
    { _id: 'MF', name: 'Mofut' },
    { _id: 'MR', name: 'Motorola' },
    { _id: 'MV', name: 'Movic' },
    { _id: 'MS', name: 'Microsoft' },
    { _id: 'M9', name: 'MTC' },
    { _id: 'MP', name: 'MegaFon' },
    { _id: 'MZ', name: 'MSI' },
    { _id: 'MU', name: 'Memup' },
    { _id: 'MT', name: 'Mitsubishi' },
    { _id: 'ML', name: 'MLLED' },
    { _id: 'MQ', name: 'M.T.T.' },
    { _id: 'N4', name: 'MTN' },
    { _id: 'MY', name: 'MyPhone' },
    { _id: '3T', name: 'MyTab' },
    { _id: '1M', name: 'MYFON' },
    { _id: 'MG', name: 'MyWigo' },
    { _id: 'M8', name: 'Myria' },
    { _id: '6M', name: 'Mystery' },
    { _id: '1R', name: 'Multilaser' },
    { _id: 'N3', name: 'Navon' },
    { _id: 'N7', name: 'National' },
    { _id: 'N5', name: 'NOA' },
    { _id: 'NE', name: 'NEC' },
    { _id: '4N', name: 'NextTab' },
    { _id: '5N', name: 'Nos' },
    { _id: '1N', name: 'Neomi' },
    { _id: 'NF', name: 'Neffos' },
    { _id: 'NA', name: 'Netgear' },
    { _id: 'NU', name: 'NeuImage' },
    { _id: 'NG', name: 'NGM' },
    { _id: 'NZ', name: 'NG Optics' },
    { _id: 'N6', name: 'Nobby' },
    { _id: 'NO', name: 'Nous' },
    { _id: 'NI', name: 'Nintendo' },
    { _id: '0N', name: 'Newman' },
    { _id: 'N1', name: 'Noain' },
    { _id: 'N2', name: 'Nextbit' },
    { _id: 'NK', name: 'Nokia' },
    { _id: 'NV', name: 'Nvidia' },
    { _id: 'NB', name: 'Noblex' },
    { _id: 'NM', name: 'Nomi' },
    { _id: '2N', name: 'Nomu' },
    { _id: 'N0', name: 'Nuvo' },
    { _id: 'NL', name: 'NUU Mobile' },
    { _id: 'NY', name: 'NYX Mobile' },
    { _id: 'NN', name: 'Nikon' },
    { _id: 'N9', name: 'Newland' },
    { _id: 'NW', name: 'Newgen' },
    { _id: 'NS', name: 'NewsMy' },
    { _id: 'NX', name: 'Nexian' },
    { _id: 'N8', name: 'NEXON' },
    { _id: 'NT', name: 'NextBook' },
    { _id: 'O3', name: 'O+' },
    { _id: 'OB', name: 'Obi' },
    { _id: 'O1', name: 'Odys' },
    { _id: 'OD', name: 'Onda' },
    { _id: 'ON', name: 'OnePlus' },
    { _id: 'OX', name: 'Onix' },
    { _id: 'OH', name: 'Openbox' },
    { _id: 'OP', name: 'OPPO' },
    { _id: 'O4', name: 'ONN' },
    { _id: 'OR', name: 'Orange' },
    { _id: 'O5', name: 'Orbic' },
    { _id: 'OS', name: 'Ordissimo' },
    { _id: 'OT', name: 'O2' },
    { _id: 'OK', name: 'Ouki' },
    { _id: 'OE', name: 'Oukitel' },
    { _id: 'OU', name: 'OUYA' },
    { _id: 'OO', name: 'Opsson' },
    { _id: 'OV', name: 'Overmax' },
    { _id: 'OY', name: 'Oysters' },
    { _id: 'O6', name: 'Oyyu' },
    { _id: 'OW', name: 'öwn' },
    { _id: 'O2', name: 'Owwo' },
    { _id: 'OZ', name: 'OzoneHD' },
    { _id: 'PN', name: 'Panacom' },
    { _id: 'PA', name: 'Panasonic' },
    { _id: 'PB', name: 'PCBOX' },
    { _id: 'PC', name: 'PCD' },
    { _id: 'PD', name: 'PCD Argentina' },
    { _id: 'PE', name: 'PEAQ' },
    { _id: 'PG', name: 'Pentagram' },
    { _id: 'PH', name: 'Philips' },
    { _id: '4P', name: 'Philco' },
    { _id: '1P', name: 'Phicomm' },
    { _id: 'PI', name: 'Pioneer' },
    { _id: 'PX', name: 'Pixus' },
    { _id: 'PL', name: 'Polaroid' },
    { _id: 'P5', name: 'Polytron' },
    { _id: 'P9', name: 'Primepad' },
    { _id: '2P', name: 'Prixton' },
    { _id: 'P6', name: 'Proline' },
    { _id: 'PM', name: 'Palm' },
    { _id: '0P', name: 'POCO' },
    { _id: '3P', name: 'Positivo BGH' },
    { _id: 'PO', name: 'phoneOne' },
    { _id: '5P', name: 'Phonemax' },
    { _id: 'PT', name: 'Pantech' },
    { _id: 'PY', name: 'Ployer' },
    { _id: 'P4', name: 'Plum' },
    { _id: 'P8', name: 'PocketBook' },
    { _id: 'PV', name: 'Point of View' },
    { _id: 'PP', name: 'PolyPad' },
    { _id: 'P2', name: 'Pomp' },
    { _id: 'P3', name: 'PPTV' },
    { _id: 'PS', name: 'Positivo' },
    { _id: 'PR', name: 'Prestigio' },
    { _id: '6P', name: 'Primux' },
    { _id: 'P7', name: 'Protruly' },
    { _id: 'P1', name: 'ProScan' },
    { _id: 'PU', name: 'PULID' },
    { _id: 'QB', name: 'Q.Bell' },
    { _id: 'QI', name: 'Qilive' },
    { _id: 'QT', name: 'Qtek' },
    { _id: 'QH', name: 'Q-Touch' },
    { _id: 'QM', name: 'QMobile' },
    { _id: 'QA', name: 'Quantum' },
    { _id: 'QU', name: 'Quechua' },
    { _id: 'QO', name: 'Qumo' },
    { _id: 'RA', name: 'Ramos' },
    { _id: 'RE', name: 'Realme' },
    { _id: 'R8', name: 'RED' },
    { _id: 'R9', name: 'Ravoz' },
    { _id: 'RZ', name: 'Razer' },
    { _id: 'RC', name: 'RCA Tablets' },
    { _id: 'RB', name: 'Readboy' },
    { _id: 'RI', name: 'Rikomagic' },
    { _id: 'RN', name: 'Rinno' },
    { _id: 'RV', name: 'Riviera' },
    { _id: 'RM', name: 'RIM' },
    { _id: 'RK', name: 'Roku' },
    { _id: 'RO', name: 'Rover' },
    { _id: 'R6', name: 'RoverPad' },
    { _id: 'RR', name: 'Roadrover' },
    { _id: 'R1', name: 'Rokit' },
    { _id: 'R3', name: 'Rombica' },
    { _id: 'RT', name: 'RT Project' },
    { _id: 'RX', name: 'Ritmix' },
    { _id: 'R7', name: 'Ritzviva' },
    { _id: 'R5', name: 'Ross&Moor' },
    { _id: 'R2', name: 'R-TV' },
    { _id: 'RG', name: 'RugGear' },
    { _id: 'RU', name: 'Runbo' },
    { _id: 'RY', name: 'Ryte' },
    { _id: 'SQ', name: 'Santin' },
    { _id: 'SA', name: 'Samsung' },
    { _id: 'S0', name: 'Sanei' },
    { _id: 'CZ', name: 'Schneider' },
    { _id: 'SD', name: 'Sega' },
    { _id: 'SL', name: 'Selfix' },
    { _id: 'SE', name: 'Sony Ericsson' },
    { _id: '01', name: 'Senkatel' },
    { _id: 'S1', name: 'Sencor' },
    { _id: 'SF', name: 'Softbank' },
    { _id: 'SX', name: 'SFR' },
    { _id: 'SG', name: 'Sagem' },
    { _id: 'SH', name: 'Sharp' },
    { _id: '7S', name: 'Shift Phones' },
    { _id: '3S', name: 'Shuttle' },
    { _id: 'SI', name: 'Siemens' },
    { _id: 'SJ', name: 'Silent Circle' },
    { _id: '1S', name: 'Sigma' },
    { _id: 'SN', name: 'Sendo' },
    { _id: '0S', name: 'SEMP TCL' },
    { _id: 'S6', name: 'Senseit' },
    { _id: 'EW', name: 'Senwa' },
    { _id: 'SW', name: 'Sky' },
    { _id: 'SK', name: 'Skyworth' },
    { _id: 'SC', name: 'Smartfren' },
    { _id: 'SO', name: 'Sony' },
    { _id: 'OI', name: 'Sonim' },
    { _id: 'X2', name: 'Soundmax' },
    { _id: '8S', name: 'Soyes' },
    { _id: 'SP', name: 'Spice' },
    { _id: '6S', name: 'Spectrum' },
    { _id: '9S', name: 'Sugar' },
    { _id: '5S', name: 'Sunvell' },
    { _id: '0H', name: 'Sunstech' },
    { _id: 'SU', name: 'SuperSonic' },
    { _id: 'S5', name: 'Supra' },
    { _id: 'SV', name: 'Selevision' },
    { _id: 'SY', name: 'Sanyo' },
    { _id: 'SM', name: 'Symphony' },
    { _id: '4S', name: 'Syrox' },
    { _id: 'SR', name: 'Smart' },
    { _id: 'S7', name: 'Smartisan' },
    { _id: 'S4', name: 'Star' },
    { _id: 'SB', name: 'STF Mobile' },
    { _id: 'S8', name: 'STK' },
    { _id: 'S9', name: 'Savio' },
    { _id: '2S', name: 'Starway' },
    { _id: 'ST', name: 'Storex' },
    { _id: 'S2', name: 'Stonex' },
    { _id: 'S3', name: 'SunVan' },
    { _id: 'SZ', name: 'Sumvision' },
    { _id: 'SS', name: 'SWISSMOBILITY' },
    { _id: 'QS', name: 'SQOOL' },
    { _id: '0W', name: 'Swipe' },
    { _id: '10', name: 'Simbans' },
    { _id: 'X1', name: 'Safaricom' },
    { _id: 'TA', name: 'Tesla' },
    { _id: 'TK', name: 'Takara' },
    { _id: '4T', name: 'Tronsmart' },
    { _id: 'T5', name: 'TB Touch' },
    { _id: 'TC', name: 'TCL' },
    { _id: 'T7', name: 'Teclast' },
    { _id: 'TE', name: 'Telit' },
    { _id: '9T', name: 'Tetratab' },
    { _id: 'T4', name: 'ThL' },
    { _id: 'TH', name: 'TiPhone' },
    { _id: 'TB', name: 'Tecno Mobile' },
    { _id: 'TP', name: 'TechPad' },
    { _id: 'TD', name: 'Tesco' },
    { _id: 'T0', name: 'TD Systems' },
    { _id: 'TI', name: 'TIANYU' },
    { _id: '2T', name: 'Tinai' },
    { _id: 'TG', name: 'Telego' },
    { _id: 'TL', name: 'Telefunken' },
    { _id: '2L', name: 'Tele2' },
    { _id: 'T2', name: 'Telenor' },
    { _id: 'TM', name: 'T-Mobile' },
    { _id: 'TN', name: 'Thomson' },
    { _id: '8T', name: 'Time2' },
    { _id: 'TQ', name: 'Timovi' },
    { _id: 'TY', name: 'Tooky' },
    { _id: '0T', name: 'Tone' },
    { _id: 'T1', name: 'Tolino' },
    { _id: 'T9', name: 'Top House' },
    { _id: 'TO', name: 'Toplux' },
    { _id: '7T', name: 'Torex' },
    { _id: 'T8', name: 'Touchmate' },
    { _id: 'TS', name: 'Toshiba' },
    { _id: 'TT', name: 'TechnoTrend' },
    { _id: 'T6', name: 'TrekStor' },
    { _id: 'T3', name: 'Trevi' },
    { _id: 'TU', name: 'Tunisie Telecom' },
    { _id: 'TR', name: 'Turbo-X' },
    { _id: '1T', name: 'Turbo' },
    { _id: '5T', name: 'TurboKids' },
    { _id: '11', name: 'True' },
    { _id: 'TV', name: 'TVC' },
    { _id: 'TW', name: 'TWM' },
    { _id: '6T', name: 'Twoe' },
    { _id: 'TX', name: 'TechniSat' },
    { _id: 'TZ', name: 'teXet' },
    { _id: 'UC', name: 'U.S. Cellular' },
    { _id: 'UH', name: 'Uhappy' },
    { _id: 'U1', name: 'Uhans' },
    { _id: 'UG', name: 'Ugoos' },
    { _id: 'UL', name: 'Ulefone' },
    { _id: 'UO', name: 'Unnecto' },
    { _id: 'UN', name: 'Unowhy' },
    { _id: 'US', name: 'Uniscope' },
    { _id: 'UX', name: 'Unimax' },
    { _id: 'UM', name: 'UMIDIGI' },
    { _id: 'UU', name: 'Unonu' },
    { _id: 'UK', name: 'UTOK' },
    { _id: 'UA', name: 'Umax' },
    { _id: 'UT', name: 'UTStarcom' },
    { _id: 'UZ', name: 'Unihertz' },
    { _id: 'VA', name: 'Vastking' },
    { _id: 'VD', name: 'Videocon' },
    { _id: 'VE', name: 'Vertu' },
    { _id: 'VN', name: 'Venso' },
    { _id: 'V5', name: 'Vivax' },
    { _id: '0V', name: 'Vipro' },
    { _id: 'VI', name: 'Vitelcom' },
    { _id: 'V7', name: 'Vinga' },
    { _id: 'VK', name: 'VK Mobile' },
    { _id: 'VS', name: 'ViewSonic' },
    { _id: 'VH', name: 'Vsmart' },
    { _id: 'V9', name: 'Vsun' },
    { _id: 'V8', name: 'Vesta' },
    { _id: 'VT', name: 'Vestel' },
    { _id: 'VR', name: 'Vernee' },
    { _id: 'V4', name: 'Verizon' },
    { _id: 'VL', name: 'Verykool' },
    { _id: 'V6', name: 'VGO TEL' },
    { _id: 'VV', name: 'Vivo' },
    { _id: 'VX', name: 'Vertex' },
    { _id: 'V3', name: 'Vinsoc' },
    { _id: 'V2', name: 'Vonino' },
    { _id: '1V', name: 'Vontar' },
    { _id: 'VG', name: 'Vorago' },
    { _id: '2V', name: 'Vorke' },
    { _id: 'V1', name: 'Voto' },
    { _id: 'VO', name: 'Voxtel' },
    { _id: 'VF', name: 'Vodafone' },
    { _id: 'VM', name: 'Vodacom' },
    { _id: 'V0', name: 'VKworld' },
    { _id: 'VY', name: 'Voyo' },
    { _id: 'VZ', name: 'Vizio' },
    { _id: 'VW', name: 'Videoweb' },
    { _id: 'VU', name: 'Vulcan' },
    { _id: 'WA', name: 'Walton' },
    { _id: 'WF', name: 'Wileyfox' },
    { _id: 'WN', name: 'Wink' },
    { _id: 'WM', name: 'Weimei' },
    { _id: 'WE', name: 'WellcoM' },
    { _id: 'WY', name: 'Wexler' },
    { _id: 'W2', name: 'Wigor' },
    { _id: 'WI', name: 'Wiko' },
    { _id: 'WS', name: 'Winds' },
    { _id: 'WP', name: 'Wieppo' },
    { _id: 'WL', name: 'Wolder' },
    { _id: 'WG', name: 'Wolfgang' },
    { _id: 'WO', name: 'Wonu' },
    { _id: 'W1', name: 'Woo' },
    { _id: 'WX', name: 'Woxter' },
    { _id: 'WR', name: 'Wortmann' },
    { _id: 'XV', name: 'X-View' },
    { _id: 'XI', name: 'Xiaomi' },
    { _id: 'XL', name: 'Xiaolajiao' },
    { _id: 'XN', name: 'Xion' },
    { _id: 'XO', name: 'Xolo' },
    { _id: 'XR', name: 'Xoro' },
    { _id: 'XG', name: 'Xgody' },
    { _id: 'YA', name: 'Yarvik' },
    { _id: 'YD', name: 'Yandex' },
    { _id: 'Y2', name: 'Yes' },
    { _id: 'YE', name: 'Yezz' },
    { _id: 'Y1', name: 'Yu' },
    { _id: 'YU', name: 'Yuandao' },
    { _id: 'YS', name: 'Yusun' },
    { _id: 'YO', name: 'Yota' },
    { _id: 'YT', name: 'Ytone' },
    { _id: 'YX', name: 'Yxtel' },
    { _id: 'ZE', name: 'Zeemi' },
    { _id: 'ZK', name: 'Zenek' },
    { _id: 'ZF', name: 'Zfiner' },
    { _id: 'ZO', name: 'Zonda' },
    { _id: 'ZI', name: 'Zidoo' },
    { _id: 'ZX', name: 'Ziox' },
    { _id: 'ZP', name: 'Zopo' },
    { _id: 'ZT', name: 'ZTE' },
    { _id: 'ZU', name: 'Zuum' },
    { _id: 'ZN', name: 'Zen' },
    { _id: 'ZY', name: 'Zync' },
    { _id: 'ZQ', name: 'ZYQ' },
    { _id: 'XS', name: 'Xshitou' },
    { _id: 'XT', name: 'X-TIGI' },
    { _id: 'XB', name: 'NEXBOX' },
    { _id: 'X3', name: 'X-BO' },

    // legacy brands}, might be removed in future versions
    { _id: 'WB', name: 'Web TV' },
    { _id: 'XX', name: 'Unknown' },
  ],
};
