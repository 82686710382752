import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import { createApp, provide } from '@vue/composition-api';
import vuetify from './plugins/vuetify';
import filters from './plugins/filters';
import Icons from './icons';
import dotenv from 'dotenv';

dotenv.config();

import VueJsonToCsv from 'vue-json-to-csv';
Vue.use(VueJsonToCsv);
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(VueCompositionApi);
Vue.use(filters);
Vue.config.productionTip = false;
Vue.config.devtools = true;

import Application from '@/view/Application';
import Router from './store/Router';
import Platform from './store/Platform';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

const app = createApp({
  render: (h) => h(Application),
  vuetify,
  router: Router,
  data: () => ({}),
  setup() {
    provide('Platform', Platform());
    const { state, actions } = require('./store/I18n');
    provide('I18n', { state, actions });
    return {};
  },
});
app.mixin({
  setup: () => {
    const { actions } = require('./store/I18n');
    const $t = (key, args) => {
      return actions.translate(key, args);
    };
    return {
      $t,
      Icons,
    };
  },
});
app.mount('#app');
