import { reactive } from '@vue/composition-api';
import _ from 'lodash';
import messages from '../messages';

export const state = reactive({
  languages: ['en'],
  language: 'en',
  messages: messages,
});

export const actions = {
  /**
   *
   * @param {String} language
   */
  changeLanguage(language) {
    state.language = language;
  },
  /**
   *
   * @param {String} key
   * @param {Object} args
   */
  translate(key, args) {
    console.debug(`translate(key=${key}, args=${args})`);
    let result = null;
    try {
      result = _.get(state.messages, `${state.language}.${key}`);
      if (!result) {
        console.error(`Error translate(key=${key}, args=${args}) => ${result}`);
        result = key;
      } else if (args) {
        Object.keys(args).forEach((key) => {
          result = result.replace(`%{${key}}`, args[key]);
        });
      }
    } catch (error) {
      result = error;
    } finally {
      console.debug(`translate(key=${key}, args=${args}) => ${result}`);
    }
    return result;
  },
};
