/**
 * This determines the operation the API is running.
 */
export default {
  NONE: 'NONE',
  IS_GETTING: 'IS_GETTING',
  IS_CREATING: 'IS_CREATING',
  IS_UPDATING: 'IS_UPDATING',
  IS_DELETING: 'IS_DELETING',
};
