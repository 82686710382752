<template>
  <span>
    <pcc-button-tooltip
      v-if="!asking && viewDelete"
      v-on:click="askEvent"
      icon="mdi-delete"
      position="bottom"
      :tooltip="$t('commons.operation.delete')"
    />
    <pcc-button-tooltip
      v-if="asking && viewDelete"
      v-on:click="deleteEvent"
      text-color="warning--text"
      icon="mdi-content-save-alert"
      position="bottom"
      :tooltip="$t('commons.operation.confirm')"
    />
    <pcc-button-tooltip
      v-if="asking"
      v-on:click="cancelEvent"
      icon="mdi-cancel"
      position="bottom"
      :tooltip="$t('commons.operation.cancel')"
    />
    <pcc-button-tooltip
      v-if="!asking && viewEdit"
      v-on:click="editEvent"
      icon="mdi-fountain-pen"
      position="bottom"
      :tooltip="$t('commons.operation.edit')"
    />
  </span>
</template>
<script>
import PccButtonTooltip from './PccButtonTooltip';

export default {
  name: 'pcc-button-edit-delete',
  components: {
    'pcc-button-tooltip': PccButtonTooltip,
  },
  data() {
    return {
      asking: false,
    };
  },
  props: {
    viewEdit: {
      type: Boolean,
      default: true,
      required: false,
    },
    viewDelete: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  methods: {
    askEvent() {
      this.asking = true;
    },
    editEvent() {
      this.$emit('edit');
    },
    deleteEvent() {
      this.$emit('delete');
      this.asking = false;
    },
    cancelEvent() {
      this.asking = false;
    },
  },
};
</script>
