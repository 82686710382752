import _ from 'lodash';
import { reactive } from '@vue/composition-api';
import HttpClientV2 from '../HttpClientV2';

const defaultGlobalStats = {
  today: {
    transactions: 0,
    conversions: 0,
    revenue: 0,
    revenueUSD: 0,
    revenueEUR: 0,
    payout: 0,
    payoutUSD: 0,
    payoutEUR: 0,
    e: 0,
    eUSD: 0,
    eEUR: 0,
    cr: 0,
    epc: 0,
    epcUSD: 0,
    epcEUR: 0,
    ppc: 0,
    ppcUSD: 0,
    ppcEUR: 0,
  },
  thisMonth: {
    transactions: 0,
    conversions: 0,
    revenue: 0,
    revenueUSD: 0,
    revenueEUR: 0,
    payout: 0,
    payoutUSD: 0,
    payoutEUR: 0,
    e: 0,
    eUSD: 0,
    eEUR: 0,
    cr: 0,
    epc: 0,
    epcUSD: 0,
    epcEUR: 0,
    ppc: 0,
    ppcUSD: 0,
    ppcEUR: 0,
  },
  lastMonth: {
    transactions: 0,
    conversions: 0,
    revenue: 0,
    revenueUSD: 0,
    revenueEUR: 0,
    payout: 0,
    payoutUSD: 0,
    payoutEUR: 0,
    e: 0,
    eUSD: 0,
    eEUR: 0,
    cr: 0,
    epc: 0,
    epcUSD: 0,
    epcEUR: 0,
    ppc: 0,
    ppcUSD: 0,
    ppcEUR: 0,
  },
  monthForecast: {
    transactions: 0,
    conversions: 0,
    revenue: 0,
    revenueUSD: 0,
    revenueEUR: 0,
    payout: 0,
    payoutUSD: 0,
    payoutEUR: 0,
    e: 0,
    eUSD: 0,
    eEUR: 0,
    cr: 0,
    epc: 0,
    epcUSD: 0,
    epcEUR: 0,
    ppc: 0,
    ppcUSD: 0,
    ppcEUR: 0,
  },
};

export default function Dashboard(currency) {
  const state = reactive({
    currency,
    symbol: currency == 'USD' ? '$' : '€',
    globalStatsLoading: false,
    globalStats: defaultGlobalStats,
    topOffersItemsLoading: false,
    topOffersItems: [],
    topLocationsItemsLoading: false,
    topLocationsItems: [],
    Notifications: [],
  });

  const actions = {
    async loadAffiliateStatistics() {
      state.globalStatsLoading = true;
      try {
        _.set(
          state,
          'globalStats',
          await HttpClientV2.callFunctionV2('GET', 'statistics/timeframe-summary')
        );
      } catch (error) {
        _.set(state, 'globalStats', defaultGlobalStats);
      }
      if (Object.keys(state.globalStats).length == 0) {
        _.set(state, 'globalStats', defaultGlobalStats);
      }
      state.globalStatsLoading = false;
    },
    async loadTopOffersItems(silent = false) {
      try {
        if (!silent) _.set(state, 'topOffersItemsLoading', true);
        _.set(
          state,
          'topOffersItems',
          await HttpClientV2.callFunctionV2('GET', 'statistics/TopStats', {
            groupBy: '_offer',
          })
        );
      } catch (error) {
        _.set(state, 'topOffersItems', []);
      } finally {
        _.set(state, 'topOffersItemsLoading', false);
      }
    },
    async loadTopLocationsItems(silent = false) {
      try {
        if (!silent) _.set(state, 'topLocationsItemsLoading', true);
        _.set(
          state,
          'topLocationsItems',
          await HttpClientV2.callFunctionV2('GET', 'statistics/TopStats', {
            groupBy: 'country',
          })
        );
      } catch (error) {
        _.set(state, 'topLocationsItems', []);
      } finally {
        _.set(state, 'topLocationsItemsLoading', false);
      }
    },
  };
  return { state, actions };
}
